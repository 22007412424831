import { notification } from 'antd';
import axios from 'axios';
import store from 'store';

const client = {}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SETTINGS_API,
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    console.log(data)
    notification.warning({
      message: data && data.message ? data.message : '',
    })
  }
})

client.get = async (endpoint, params = null) => {
  return apiClient
    .get(endpoint, { params })
    .then(response => response)
    .catch(err => {
      return err
    })
}

client.post = async (endpoint, data) => {
  return apiClient
    .post(endpoint, data)
    .then(response => response)
    .catch(err => {
      return err
    })
}

client.put = async (endpoint, data) => {
  return apiClient
    .put(endpoint, data)
    .then(response => response)
    .catch(err => {
      return err
    })
}

client.delete = async endpoint => {
  return apiClient
    .put(endpoint)
    .then(response => response)
    .catch(err => {
      return err
    })
}

export default client
