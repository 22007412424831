import { notification } from 'antd'
import { history } from 'index'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import * as jwt from 'services/jwt'

import client from '../../services/axios/client'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  const { centerId, password } = payload
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, centerId, password)
  if (success) {
    yield put({
      type: 'auth/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
  if (!success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_CENTERS() {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield client.get('auth/centers')
  if (response && response.status === 200) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        centers: response.data,
      },
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* RESET_PASSWORD({ payload }) {
  const { email } = payload
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield client.post('auth/forgot', email)
  if (response.status === 1) {
    yield history.push('/login')
    notification.success({
      message: 'Success',
      description: 'Parola a fost schimbata cu succes!',
    })
  }
  if (!response.status !== 1) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, firstName, lastName } = payload
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(
    mapAuthProviders[authProvider].register,
    email,
    password,
    firstName,
    lastName,
  )
  if (success) {
    yield history.push('/')
    yield put({
      type: 'auth/LOAD_CURRENT_ACCOUNT',
    })

    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  /**
   * I am sure there's a better and elegant way to do this...but .... fuck it...
   * who will find this, his duty will be to fix it.
   */
  if (window.location.href.indexOf('auth/register') === -1) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: true,
      },
    })

    const { authProvider } = yield select(state => state.settings)
    const response = yield call(mapAuthProviders[authProvider].currentAccount)
    if (response) {
      const { id, email, name, avatar, role } = response
      yield put({
        type: 'auth/SET_STATE',
        payload: {
          id,
          name,
          email,
          avatar,
          role,
          authorized: true,
        },
      })
    }
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.GET_CENTERS, GET_CENTERS),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
