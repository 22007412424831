import actions from './actions'

const initialState = {
  list: { data: [], meta: {} },
  patient: {},
  searchList: [],
  countiesList: [],
  epData: {},
  ecoData: {},
  newProcedureId: '',
  citiesList: [],
  probes: [],
  electrodes: [],
  pdf: '',
  doctorsList: [],
  procedureDoctors: [],
  // loading vas
  listMeta: {},
  loading: false,
  loadingEcoData: false,
}

export default function patientsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
