import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import labs from './labs/sagas';
import menu from './menu/sagas';
import patients from './patients/sagas';
import reports from './reports/sagas';
import settings from './settings/sagas';
import users from './users/sagas';

export default function* rootSaga() {
  yield all([auth(), menu(), settings(), users(), labs(), patients(), reports()])
}
