const actions = {
  SET_STATE: 'reports/SET_STATE',
  GET_FINANCE_REPORTS: 'reports/GET_FINANCE_REPORTS',
  GET_DEVICE_REPORTS: 'reports/GET_DEVICE_REPORTS',
  DOWNLOAD_DEVICE_REPORT: 'reports/DOWNLOAD_DEVICE_REPORT',
  DOWNLOAD_FINANCE_REPORT: 'reports/DOWNLOAD_FINANCE_REPORT',
  GET_FINANCE_SOURCES: 'reports/GET_FINANCE_SOURCES'
}

export default actions
