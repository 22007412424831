import apiClient from 'services/axios'

const reportsApi = {
    getFinanceReports() {
        return apiClient
        .get('finance/reports')
        .then(response => response)
        .catch(err => {
            console.log(err)
            return err
        })
    },

    getFinanceSources() {
        return apiClient
        .get('reports/finance/finance-sources')
        .then(response => response)
        .catch(err => {
            console.log(err)
            return err
        })
    },
    getDeviceReports() {
        return apiClient
        .get('device/reports')
        .then(response => response)
        .catch(err => {
            console.log(err)
            return err
        })
    },
    downloadDeviceReport(data) {
        return apiClient
        .post(`device/reports/download`, { ...data })
        .then(response => response)
        .catch(err => {
            console.log(err)
            return err
        })
    },
    downloadFinanceReport(data) {
        return apiClient
        .post(`reports/finance/download`, { ...data })
        .then(response => response)
        .catch(err => {
            console.log(err)
            return err
        })
    }

}

export default reportsApi
