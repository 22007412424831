import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import auth from './auth/reducers';
import labs from './labs/reducers';
import menu from './menu/reducers';
import patients from './patients/reducers';
import reports from './reports/reducers';
import settings from './settings/reducers';
import users from './users/reducers';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    menu,
    settings,
    users,
    labs,
    patients,
    reports,
  })
